var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ax from "axios";
/*! js-cookie v3.0.1 | MIT */
function assign(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      target[key] = source[key];
    }
  }
  return target;
}
var defaultConverter = {
  read: function(value) {
    if (value[0] === '"') {
      value = value.slice(1, -1);
    }
    return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(value) {
    return encodeURIComponent(value).replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g, decodeURIComponent);
  }
};
function init(converter, defaultAttributes) {
  function set(key, value, attributes) {
    if (typeof document === "undefined") {
      return;
    }
    attributes = assign({}, defaultAttributes, attributes);
    if (typeof attributes.expires === "number") {
      attributes.expires = new Date(Date.now() + attributes.expires * 864e5);
    }
    if (attributes.expires) {
      attributes.expires = attributes.expires.toUTCString();
    }
    key = encodeURIComponent(key).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
    var stringifiedAttributes = "";
    for (var attributeName in attributes) {
      if (!attributes[attributeName]) {
        continue;
      }
      stringifiedAttributes += "; " + attributeName;
      if (attributes[attributeName] === true) {
        continue;
      }
      stringifiedAttributes += "=" + attributes[attributeName].split(";")[0];
    }
    return document.cookie = key + "=" + converter.write(value, key) + stringifiedAttributes;
  }
  function get(key) {
    if (typeof document === "undefined" || arguments.length && !key) {
      return;
    }
    var cookies = document.cookie ? document.cookie.split("; ") : [];
    var jar = {};
    for (var i = 0; i < cookies.length; i++) {
      var parts = cookies[i].split("=");
      var value = parts.slice(1).join("=");
      try {
        var foundKey = decodeURIComponent(parts[0]);
        jar[foundKey] = converter.read(value, foundKey);
        if (key === foundKey) {
          break;
        }
      } catch (e) {
      }
    }
    return key ? jar[key] : jar;
  }
  return Object.create({
    set,
    get,
    remove: function(key, attributes) {
      set(key, "", assign({}, attributes, {
        expires: -1
      }));
    },
    withAttributes: function(attributes) {
      return init(this.converter, assign({}, this.attributes, attributes));
    },
    withConverter: function(converter2) {
      return init(assign({}, this.converter, converter2), this.attributes);
    }
  }, {
    attributes: { value: Object.freeze(defaultAttributes) },
    converter: { value: Object.freeze(converter) }
  });
}
var api = init(defaultConverter, { path: "/" });
class InMemorySessionStore {
  constructor() {
    __publicField(this, "_token", null);
    __publicField(this, "_scopes", null);
  }
  setToken(token) {
    this._token = token;
  }
  setScopes(scopes) {
    this._scopes = scopes;
  }
  getToken() {
    return this._token;
  }
  getScopes() {
    return this._scopes;
  }
  isLoggedIn() {
    return this._token !== null;
  }
  deleteSession() {
    this._token = null;
    this._scopes = null;
  }
}
const defaultCookieOptions = {
  domain: void 0,
  path: "/",
  secure: typeof window !== "undefined" ? window.location.protocol === "https" : false,
  sameSite: "Strict"
};
const AUTH_COOKIE_NAME = "puffer_auth";
const SCOPES_COOKIE_NAME = "puffer_scopes";
class ClientCookieSessionStore {
  constructor(options = defaultCookieOptions) {
    __publicField(this, "_cookieOptions", null);
    this._cookieOptions = options;
  }
  setToken(token) {
    api.set(AUTH_COOKIE_NAME, token, this._cookieOptions);
  }
  setScopes(scopes) {
    api.set(SCOPES_COOKIE_NAME, JSON.stringify(scopes), this._cookieOptions);
  }
  getToken() {
    return api.get(AUTH_COOKIE_NAME) || null;
  }
  getScopes() {
    const res = api.get(SCOPES_COOKIE_NAME);
    if (res)
      return JSON.parse(res);
    return null;
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  deleteSession() {
    api.remove(AUTH_COOKIE_NAME, this._cookieOptions);
    api.remove(SCOPES_COOKIE_NAME, this._cookieOptions);
  }
}
const AUTH_EXP_COOKIE_NAME = "puffer_auth_expires";
class ServerCookieSessionStore {
  constructor(options = defaultCookieOptions) {
    __publicField(this, "_cookieOptions", null);
    this._cookieOptions = options;
  }
  setToken() {
    throw new Error("It seems you want the ClientCookieSessionStore, not the ServerCookieSessionStore");
  }
  setScopes(scopes) {
    api.set(SCOPES_COOKIE_NAME, JSON.stringify(scopes), this._cookieOptions);
  }
  getToken() {
    return null;
  }
  getScopes() {
    const res = api.get(SCOPES_COOKIE_NAME);
    if (res)
      return JSON.parse(res);
    return null;
  }
  isLoggedIn() {
    const res = api.get(AUTH_EXP_COOKIE_NAME);
    return res !== void 0;
  }
  deleteSession() {
    api.remove(AUTH_EXP_COOKIE_NAME, this._cookieOptions);
    api.remove(SCOPES_COOKIE_NAME, this._cookieOptions);
  }
}
function is2xx(status) {
  return status >= 200 && status < 300;
}
class AuthApi {
  constructor(api2, sessionStore) {
    __publicField(this, "_api", null);
    __publicField(this, "_sessionStore", null);
    this._api = api2;
    this._sessionStore = sessionStore;
  }
  _handleLogin(scopes) {
    this._sessionStore.setScopes(scopes);
    return true;
  }
  async oauth(clientId, clientSecret) {
    const res = await this._api.post("/oauth2/token", `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`);
    this._sessionStore.setToken(res.data.access_token);
    return true;
  }
  async login(email, password) {
    const res = await this._api.post("/auth/login", { email, password });
    if (res.data.otpNeeded)
      return "otp";
    return this._handleLogin(res.data.scopes);
  }
  async loginOtp(token) {
    const res = await this._api.post("/auth/otp", { token });
    return this._handleLogin(res.data.scopes);
  }
  async register(username, email, password) {
    const res = await this._api.post("/auth/register", { username, email, password });
    return this._handleLogin(res.data.scopes);
  }
  async reauth() {
    const res = await this._api.post("/auth/reauth");
    if (is2xx(res.status)) {
      this._handleLogin(res.data.scopes);
    }
  }
  getToken() {
    return this._sessionStore.getToken();
  }
  isLoggedIn() {
    return this._sessionStore.isLoggedIn();
  }
  hasScope(scope) {
    if (!this.isLoggedIn())
      return false;
    const scopes = this._sessionStore.getScopes();
    if (scopes !== null) {
      if (scopes.indexOf(scope) !== -1)
        return true;
      return scopes.indexOf("admin") !== -1;
    }
    return false;
  }
  async logout() {
    await this._api.post("/auth/logout");
    this._sessionStore.deleteSession();
  }
}
class SelfApi {
  constructor(api2) {
    __publicField(this, "_api", null);
    this._api = api2;
  }
  async get() {
    const res = await this._api.get("/api/self");
    return res.data;
  }
  async updateDetails(username, email, password) {
    await this._api.put("/api/self", { username, email, password });
    return true;
  }
  async changePassword(password, newPassword) {
    await this._api.put("/api/self", { password, newPassword });
    return true;
  }
  async isOtpEnabled() {
    const res = await this._api.get("/api/self/otp");
    return res.data.otpEnabled;
  }
  async startOtpEnroll() {
    const res = await this._api.post("/api/self/otp");
    return res.data;
  }
  async validateOtpEnroll(token) {
    await this._api.put("/api/self/otp", { token });
    return true;
  }
  async disableOtp(token) {
    await this._api.delete(`/api/self/otp/${token}`);
    return true;
  }
  async getSettings() {
    const res = await this._api.get("/api/userSettings");
    const map = {};
    res.data.map((e) => map[e.key] = e.value);
    return map;
  }
  async updateSetting(key, value) {
    await this._api.put(`/api/userSettings/${key}`, { value });
    return true;
  }
  async getOAuthClients() {
    const res = await this._api.get(`/api/self/oauth2`);
    return res.data;
  }
  async createOAuthClient(name, description) {
    const res = await this._api.post(`/api/self/oauth2`, { name, description });
    return res.data;
  }
  async deleteOAuthClient(clientId) {
    await this._api.delete(`/api/self/oauth2/${clientId}`);
    return true;
  }
}
class UserApi {
  constructor(api2) {
    __publicField(this, "_api", null);
    this._api = api2;
  }
  async list(page = 1) {
    const res = await this._api.get("/api/users", { page });
    return res.data;
  }
  async search(name, limit) {
    const query = { username: `*${name}*` };
    if (limit)
      query.limit = limit;
    const res = await this._api.get("/api/users", query);
    return res.data.users;
  }
  async searchEmail(email, limit) {
    const query = { email: `*${email}*` };
    if (limit)
      query.limit = limit;
    const res = await this._api.get("/api/users", query);
    return res.data.users;
  }
  async create(username, email, password) {
    const res = await this._api.post("/api/users", { username, email, password });
    return res.data.id;
  }
  async get(id) {
    const res = await this._api.get(`/api/users/${id}`);
    return res.data;
  }
  async getPermissions(id) {
    const res = await this._api.get(`/api/users/${id}/perms`);
    return res.data.scopes;
  }
  async update(id, user) {
    await this._api.post(`/api/users/${id}`, user);
    return true;
  }
  async updatePermissions(id, permissions) {
    await this._api.put(`/api/users/${id}/perms`, permissions);
    return true;
  }
  async delete(id) {
    await this._api.delete(`/api/users/${id}`);
    return true;
  }
}
class NodeApi {
  constructor(api2) {
    __publicField(this, "_api", null);
    this._api = api2;
  }
  fixNode(node) {
    return __spreadProps(__spreadValues({}, node), {
      publicPort: Number(node.publicPort),
      privatePort: Number(node.privatePort),
      sftpPort: Number(node.sftpPort)
    });
  }
  async list() {
    const res = await this._api.get("/api/nodes");
    return res.data;
  }
  async get(id) {
    const res = await this._api.get(`/api/nodes/${id}`);
    return res.data;
  }
  async deployment(id) {
    const res = await this._api.get(`/api/nodes/${id}/deployment`);
    return res.data;
  }
  async features(id) {
    const res = await this._api.get(`/api/nodes/${id}/features`, void 0, void 0, { unhandledErrors: [500] });
    return res.data;
  }
  async create(node) {
    await this._api.post("/api/nodes/", this.fixNode(node));
    try {
      const nodes = await this.list();
      return nodes.find((n) => n.name === node.name).id;
    } catch (e) {
      return -1;
    }
  }
  async update(id, node) {
    await this._api.put(`/api/nodes/${id}`, this.fixNode(node));
    return true;
  }
  async delete(id) {
    await this._api.delete(`/api/nodes/${id}`);
    return true;
  }
}
class ServerApi {
  constructor(api2) {
    __publicField(this, "_api", null);
    this._api = api2;
  }
  async create(data) {
    const c = (crypto || {}).getRandomValues ? crypto : ((crypto || {}).webcrypto || {}).getRandomValues ? crypto.webcrypto.getRandomValues : void 0;
    if (c === void 0)
      throw new Error("no suitable crypto found");
    const id = Array.from(c.getRandomValues(new Uint8Array(4))).map((b) => b.toString(16).padStart(2, "0")).join("");
    await this._api.put(`/api/servers/${id}`, data);
    return id;
  }
  async list(page = 1, pageSize, name) {
    const query = { page };
    if (pageSize)
      query.limit = pageSize;
    if (name)
      query.name = `*${name}*`;
    const res = await this._api.get("/api/servers", query);
    return res.data;
  }
  async get(id, withSocket = true) {
    const res = await this._api.get(`/api/servers/${id}?perms`);
    if (withSocket) {
      return new Server(this._api, res.data);
    } else {
      return res.data;
    }
  }
  async getStatus(id) {
    const res = await this._api.get(`/api/servers/${id}/status`);
    if (res.data.installing)
      return "installing";
    if (res.data.running)
      return "online";
    return "offline";
  }
  async getStats(id) {
    const res = await this._api.get(`/api/servers/${id}/stats`);
    return res.data;
  }
  async getQuery(id) {
    const res = await this._api.get(`/api/servers/${id}/query`);
    return res.data;
  }
  async canQuery(id) {
    await this._api.head(`/api/servers/${id}/query`);
    return true;
  }
  async action(id, action, wait = false) {
    await this._api.post(`/api/servers/${id}/${action}?wait=${wait}`);
    return true;
  }
  async start(id, wait = false) {
    return await this.action(id, "start", wait);
  }
  async stop(id, wait = false) {
    return await this.action(id, "stop", wait);
  }
  async kill(id, wait = false) {
    return await this.action(id, "kill", wait);
  }
  async install(id, wait = false) {
    return await this.action(id, "install", wait);
  }
  async reload(id) {
    await this._api.post(`/api/servers/${id}/reload`);
    return true;
  }
  async sendCommand(id, command) {
    await this._api.post(`/api/servers/${id}/console`, command);
    return true;
  }
  async getConsole(id, time = 0) {
    const res = await this._api.get(`/api/servers/${id}/console?time=${time}`);
    return res.data;
  }
  async updateName(id, name) {
    await this._api.put(`/api/servers/${id}/name/${encodeURIComponent(name)}`);
    return true;
  }
  async getFlags(id) {
    const res = await this._api.get(`/api/servers/${id}/flags`);
    return res.data;
  }
  async setFlags(id, flags) {
    await this._api.post(`/api/servers/${id}/flags`, flags);
    return true;
  }
  async getDefinition(id) {
    const res = await this._api.get(`/api/servers/${id}/definition`);
    return res.data;
  }
  async updateDefinition(id, data) {
    await this._api.put(`/api/servers/${id}/definition`, data);
    return true;
  }
  async getData(id) {
    const res = await this._api.get(`/api/servers/${id}/data`);
    return res.data;
  }
  async adminUpdateData(id, data) {
    await this._api.put(`/api/servers/${id}/data`, data);
    return true;
  }
  async updateData(id, data) {
    await this._api.post(`/api/servers/${id}/data`, data);
    return true;
  }
  async getUsers(id) {
    const res = await this._api.get(`/api/servers/${id}/user`);
    return res.data;
  }
  async getUser(id, email) {
    const res = await this._api.get(`/api/servers/${id}/user/${email}`);
    return res.data;
  }
  async updateUser(id, user) {
    await this._api.put(`/api/servers/${id}/user/${user.email}`, user);
    return true;
  }
  async deleteUser(id, email) {
    await this._api.delete(`/api/servers/${id}/user/${email}`);
    return true;
  }
  getFileUrl(id, path) {
    if (path.indexOf("/") === 0)
      path = path.substring(1);
    path = encodeURIComponent(path);
    path = path.replace(/%2F/g, "/");
    return `/api/servers/${id}/file/${path}`;
  }
  async getFile(id, path = "", raw = false) {
    if (path.indexOf("/") === 0)
      path = path.substring(1);
    const res = await this._api.get(this.getFileUrl(id, path), void 0, void 0, raw ? { responseType: "arraybuffer" } : void 0);
    if (raw) {
      return new TextDecoder("utf-8").decode(new Uint8Array(res.data));
    } else {
      return res.data;
    }
  }
  async fileExists(id, path) {
    if (path.indexOf("/") === 0)
      path = path.substring(1);
    try {
      const res = await this._api._axios.get(this._api._host + this.getFileUrl(id, path));
      if (res.headers["content-disposition"]) {
        return "file";
      } else {
        return "folder";
      }
    } catch (e) {
      if (e.response.status === 404)
        return false;
      this._api._handleError(e);
    }
  }
  async uploadFile(id, path, content, onUploadProgress) {
    if (path.indexOf("/") === 0)
      path = path.substring(1);
    let blob = null;
    if (content instanceof Blob || content instanceof File) {
      blob = content;
    } else if (typeof content === "string") {
      blob = new Blob([content]);
    } else {
      blob = new Blob([JSON.stringify(content)]);
    }
    const data = new FormData();
    data.append("file", blob);
    await this._api.put(this.getFileUrl(id, path), data, void 0, { "Content-Type": "multipart/formdata" }, { onUploadProgress });
    return true;
  }
  async createFolder(id, path) {
    if (path.indexOf("/") === 0)
      path = path.substring(1);
    await this._api.put(this.getFileUrl(id, path), void 0, { folder: true });
    return true;
  }
  async archiveFile(id, destination, files) {
    if (destination.startsWith("/"))
      destination = destination.substring(1);
    if (!Array.isArray(files))
      files = [files];
    files.map((file) => file.startsWith("/") ? file.substring(1) : file);
    await this._api.post(`/api/servers/${id}/archive/${destination}`, files);
    return true;
  }
  async extractFile(id, path, destination) {
    if (path.startsWith("/"))
      path = path.substring(1);
    await this._api.post(`/api/servers/${id}/extract/${path}`, void 0, { destination });
    return true;
  }
  async deleteFile(id, path) {
    if (path.indexOf("/") === 0)
      path = path.substring(1);
    await this._api.delete(this.getFileUrl(id, path));
    return true;
  }
  async delete(id) {
    await this._api.delete(`/api/servers/${id}`);
    return true;
  }
}
class Server {
  constructor(api2, serverData) {
    __publicField(this, "_expectClose", false);
    __publicField(this, "_connectionAttemptsFailed", 0);
    __publicField(this, "_connectionAttemptsMax", 5);
    __publicField(this, "_connectionFailReset", null);
    __publicField(this, "_socket", null);
    __publicField(this, "_api", null);
    __publicField(this, "_tasks", []);
    __publicField(this, "_emitter", null);
    __publicField(this, "readyState", WebSocket.CONNECTING);
    this._emitter = {
      events: {},
      emit(event, ...args) {
        let callbacks = this.events[event] || [];
        for (let i = 0, length = callbacks.length; i < length; i++) {
          callbacks[i](...args);
        }
      },
      on(event, cb) {
        var _a;
        ((_a = this.events[event]) == null ? void 0 : _a.push(cb)) || (this.events[event] = [cb]);
        return () => {
          var _a2;
          this.events[event] = (_a2 = this.events[event]) == null ? void 0 : _a2.filter((i) => cb !== i);
        };
      }
    };
    this.id = serverData.server.id;
    this.ip = serverData.server.id;
    this.name = serverData.server.name;
    this.node = serverData.server.node;
    this.port = serverData.server.port;
    this.type = serverData.server.type;
    this._scopes = serverData.permissions.scopes;
    this._api = api2;
    this._openSocket();
    this.emit("open");
  }
  hasScope(scope) {
    if (this._scopes.indexOf(scope) !== -1)
      return true;
    if (this._scopes.indexOf("server.admin") !== -1)
      return true;
    return this._api.auth.hasScope("admin");
  }
  on(event, cb) {
    return this._emitter.on(event, cb);
  }
  emit(event, data) {
    return this._emitter.emit(event, data);
  }
  _openSocket() {
    let host = this._api._host;
    if (!host && typeof window !== "undefined") {
      host = window.location.host;
    }
    if (!host)
      throw new Error("cannot determine host to connect to");
    const protocol = host.indexOf("https://") === 0 ? "wss" : "ws";
    if (host.indexOf("http://") === 0)
      host = host.substr(7);
    if (host.indexOf("https://") === 0)
      host = host.substr(8);
    this._socket = new WebSocket(`${protocol}://${host}/api/servers/${this.id}/socket`);
    this.readyState = this._socket.readyState;
    this._socket.addEventListener("open", (e) => this._onOpen(e));
    this._socket.addEventListener("message", (e) => this._onMessage(e));
    this._socket.addEventListener("close", (e) => this._onClose(e));
  }
  _onOpen(e) {
    this.readyState = this._socket.readyState;
    this.emit("socket-open", e);
  }
  _onMessage(e) {
    this.readyState = this._socket.readyState;
    const event = JSON.parse(e.data);
    this.emit("message", event);
    this.emit(event.type, event.data);
  }
  _onClose(e) {
    this.readyState = this._socket.readyState;
    this.emit("socket-close", e);
    clearTimeout(this._connectionFailReset);
    if (this._expectClose) {
      this._cleanup();
    } else {
      console.warn("socket closed", e);
      if (this._connectionAttemptsFailed === this._connectionAttemptsMax) {
        this._onError({ msg: "Socket closed unexpectedly", event: e });
      }
      this._connectionAttemptsFailed += 1;
      this._connectionFailReset = setTimeout(() => {
        this._connectionAttemptsFailed = 0;
      }, 3e4);
      setTimeout(() => this._openSocket(), 5e3);
    }
  }
  _onError(e) {
    console.error("SOCKET ERROR", e);
    this.readyState = this._socket.readyState;
    this.emit("error", e);
  }
  _cleanup() {
    this._tasks.forEach((task) => clearInterval(task));
  }
  startTask(f, interval) {
    f();
    const task = setInterval(f, interval);
    this._tasks.push(task);
    return task;
  }
  stopTask(ref) {
    for (const task of this._tasks) {
      if (task === ref)
        clearInterval(ref);
    }
  }
  needsPolling() {
    const state = this._socket.readyState;
    return state === WebSocket.CLOSING || state === WebSocket.CLOSED;
  }
  async getStatus() {
    return await this._api.server.getStatus(this.id);
  }
  async getStats() {
    return await this._api.server.getStats(this.id);
  }
  async getQuery() {
    return await this._api.server.getQuery(this.id);
  }
  async canQuery() {
    return await this._api.server.canQuery(this.id);
  }
  async start() {
    return await this._api.server.start(this.id);
  }
  async stop() {
    return await this._api.server.stop(this.id);
  }
  async kill() {
    return await this._api.server.kill(this.id);
  }
  async install() {
    return await this._api.server.install(this.id);
  }
  async reload() {
    return await this._api.server.reload(this.id);
  }
  async sendCommand(command) {
    return await this._api.server.sendCommand(this.id, command);
  }
  async getConsole(since = 0) {
    return await this._api.server.getConsole(this.id, since);
  }
  async updateName(name) {
    const r = await this._api.server.updateName(this.id, name);
    this.name = name;
    return r;
  }
  async getFlags() {
    return await this._api.server.getFlags(this.id);
  }
  async setFlags(flags) {
    return await this._api.server.setFlags(this.id, flags);
  }
  async getDefinition() {
    return await this._api.server.getDefinition(this.id);
  }
  async updateDefinition(data) {
    return await this._api.server.updateDefinition(this.id, data);
  }
  async getData() {
    return await this._api.server.getData(this.id);
  }
  async adminUpdateData(data) {
    return await this._api.server.adminUpdateData(this.id, data);
  }
  async updateData(data) {
    return await this._api.server.updateData(this.id, data);
  }
  async delete() {
    return await this._api.server.delete(this.id);
  }
  async getUsers() {
    return await this._api.server.getUsers(this.id);
  }
  async updateUser(user) {
    return await this._api.server.updateUser(this.id, user);
  }
  async deleteUser(email) {
    return await this._api.server.deleteUser(this.id, email);
  }
  getFileUrl(path) {
    return this._api.server.getFileUrl(this.id, path);
  }
  async getFile(path = "", raw = false) {
    return await this._api.server.getFile(this.id, path, raw);
  }
  async fileExists(path) {
    return await this._api.server.fileExists(this.id, path);
  }
  async uploadFile(path, content, onUploadProgress) {
    return await this._api.server.uploadFile(this.id, path, content, onUploadProgress);
  }
  async createFolder(path) {
    return await this._api.server.createFolder(this.id, path);
  }
  async archiveFile(destination, files) {
    return await this._api.server.archiveFile(this.id, destination, files);
  }
  async extractFile(path, destination) {
    return await this._api.server.extractFile(this.id, path, destination);
  }
  async deleteFile(path) {
    return await this._api.server.deleteFile(this.id, path);
  }
  async getOAuthClients() {
    return await this._api.server.getOAuthClients(this.id);
  }
  async createOAuthClient(name, description) {
    return await this._api.server.createOAuthClient(this.id, name, description);
  }
  async deleteOAuthClient(clientId) {
    return await this._api.server.deleteOAuthClient(this.id, clientId);
  }
  closeSocket() {
    this._expectClose = true;
    this._cleanup();
    if (this.readyState === WebSocket.CONNECTING || this.readyState === WebSocket.OPEN) {
      this._socket.addEventListener("close", () => this.emit("close"));
      this._socket.close();
    } else {
      this.emit("close");
    }
  }
}
class TemplateApi {
  constructor(api2) {
    __publicField(this, "_api", null);
    this._api = api2;
  }
  async listRepos() {
    const res = await this._api.get("/api/templates");
    return res.data;
  }
  async listRepoTemplates(repo) {
    const res = await this._api.get(`/api/templates/${repo}`);
    return res.data;
  }
  async listAllTemplates() {
    const res = [];
    const repos = await this.listRepos();
    await Promise.all(repos.map(async (repo) => {
      res.push({ name: repo.name, id: repo.id, templates: await this.listRepoTemplates(repo.id) });
    }));
    return res;
  }
  async get(repo, name) {
    const res = await this._api.get(`/api/templates/${repo}/${name}`);
    return res.data;
  }
  async exists(repo, name) {
    try {
      const res = await this._api.get(`/api/templates/${repo}/${name}`, void 0, void 0, { unhandledErrors: [404] });
      if (!res.status || res.status >= 400)
        throw new Error("template doesn't exist, behave yourself js...");
      return true;
    } catch (e) {
      return false;
    }
  }
  async save(name, template) {
    await this._api.put(`/api/templates/0/${name}`, template);
    return true;
  }
  async delete(name) {
    await this._api.delete(`/api/templates/0/${name}`);
    return true;
  }
  async getRepo(repo) {
    const res = await this._api.get(`/api/templates/${repo}`);
    return res.data;
  }
  async saveRepo(repo, config) {
    await this._api.put(`/api/templates/${repo}`, config);
    return true;
  }
  async deleteRepo(repo) {
    await this._api.delete(`/api/templates/${repo}`);
    return true;
  }
}
class SettingsApi {
  constructor(api2) {
    __publicField(this, "_api", null);
    this._api = api2;
  }
  async get(key) {
    const res = await this._api.get(`/api/settings/${key}`);
    return res.data.value;
  }
  async set(data) {
    const res = await this._api.post("/api/settings/", data);
    return res.data.value;
  }
  async getUserSettings() {
    const res = await this._api.get("/api/userSettings/");
    const map = {};
    res.data.map((e) => {
      map[e.key] = e.value;
    });
    return map;
  }
  async setUserSetting(key, value) {
    await this._api.put(`/api/userSettings/${key}`, { value });
    return true;
  }
}
class ApiClient {
  constructor(host, sessionStore = new InMemorySessionStore(), errorHandler, axios = ax.create()) {
    __publicField(this, "_axios", null);
    __publicField(this, "_host", null);
    __publicField(this, "_errorHandler", null);
    __publicField(this, "auth", null);
    __publicField(this, "self", null);
    __publicField(this, "server", null);
    __publicField(this, "node", null);
    __publicField(this, "user", null);
    __publicField(this, "template", null);
    this._axios = axios;
    this._host = host;
    this._errorHandler = errorHandler;
    this.auth = new AuthApi(this, sessionStore);
    this.self = new SelfApi(this);
    this.server = new ServerApi(this);
    this.node = new NodeApi(this);
    this.user = new UserApi(this);
    this.template = new TemplateApi(this);
    this.settings = new SettingsApi(this);
  }
  _handleError(e) {
    console.error(e);
    const status = ((e || {}).response || {}).status;
    const statusText = ((e || {}).response || {}).statusText;
    const response = ((e || {}).response || {}).data;
    const request = (e || {}).config;
    let code = "ErrUnknownError";
    let msg;
    const error = (((e || {}).response || {}).data || {}).error;
    if ((error || {}).code)
      code = error.code;
    if ((error || {}).msg)
      msg = error.msg;
    const result = { status, statusText, response, request, code, msg };
    if (this._errorHandler)
      this._errorHandler(result);
    throw result;
  }
  _enhanceHeaders(headers) {
    const token = this.auth.getToken();
    if (token) {
      return __spreadProps(__spreadValues({}, headers), {
        Authorization: "Bearer " + token
      });
    }
    return headers;
  }
  async head(url, params = {}, headers = {}, options = {}) {
    try {
      return await this._axios.head(this._host + url, __spreadProps(__spreadValues({ params }, options), { headers: this._enhanceHeaders(headers) }));
    } catch (e) {
      if (!Array.isArray(options.unhandledErrors) || options.unhandledErrors.indexOf(e.response.status) === -1)
        this._handleError(e);
    }
  }
  async get(url, params = {}, headers = {}, options = {}) {
    try {
      return await this._axios.get(this._host + url, __spreadProps(__spreadValues({ params }, options), { headers: this._enhanceHeaders(headers) }));
    } catch (e) {
      if (!Array.isArray(options.unhandledErrors) || options.unhandledErrors.indexOf(e.response.status) === -1)
        this._handleError(e);
    }
  }
  async post(url, data, params = {}, headers = {}, options = {}) {
    try {
      return await this._axios.post(this._host + url, data, __spreadProps(__spreadValues({ params }, options), { headers: this._enhanceHeaders(headers) }));
    } catch (e) {
      if (!Array.isArray(options.unhandledErrors) || options.unhandledErrors.indexOf(e.response.status) === -1)
        this._handleError(e);
    }
  }
  async put(url, data, params = {}, headers = {}, options = {}) {
    try {
      return await this._axios.put(this._host + url, data, __spreadProps(__spreadValues({ params }, options), { headers: this._enhanceHeaders(headers) }));
    } catch (e) {
      if (!Array.isArray(options.unhandledErrors) || options.unhandledErrors.indexOf(e.response.status) === -1)
        this._handleError(e);
    }
  }
  async delete(url, params = {}, headers = {}, options = {}) {
    try {
      return await this._axios.delete(this._host + url, __spreadProps(__spreadValues({ params }, options), { headers: this._enhanceHeaders(headers) }));
    } catch (e) {
      if (!Array.isArray(options.unhandledErrors) || options.unhandledErrors.indexOf(e.response.status) === -1)
        this._handleError(e);
    }
  }
  async getConfig() {
    const res = await this.get("/api/config");
    return res.data;
  }
  async getTheme(name) {
    const res = await this.get(`/theme/${name}.tar`, void 0, void 0, { responseType: "arraybuffer" });
    return res.data;
  }
}
export { ApiClient, ClientCookieSessionStore, InMemorySessionStore, ServerCookieSessionStore };
